import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'reverse',
  standalone: true,
})
export class ReversePipe implements PipeTransform {
  transform<T extends unknown>(value: T[]): T[] {
    if (!value) return [];

    return value.reverse();
  }
}
